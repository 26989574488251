
import { dispatchUserLogOut } from "@/store/main/actions";
import { Component, Prop, Vue } from "vue-property-decorator";

@Component
export default class MainMenuDrawer extends Vue {
  @Prop({ default: false }) value!: boolean;
  @Prop({ default: [] }) items!: any[];
  @Prop({ default: null }) userProfile!: any;
  drawerHeight = "100%";
  get showDrawer() {
    return this.value;
  }
  set showDrawer(value: boolean) {
    this.$emit("input", value);
  }
  public mounted() {
    this.drawerHeight = window.innerHeight + "px";
    window.addEventListener("resize", this.onResize);
  }
  public unmounted() {
    window.removeEventListener("resize", this.onResize);
  }
  onResize() {
    this.drawerHeight = window.innerHeight + "px";
  }
  async logout() {
    await dispatchUserLogOut(this.$store);
    this.showDrawer = false;
    this.$router.push("/");
  }
}

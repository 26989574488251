
import { Component, Vue } from "vue-property-decorator";
import NotificationsManager from "@/components/NotificationsManager.vue";
import MainMenuDrawer from "@/components/MainMenuDrawer.vue";
import { readUserProfile } from "./store/main/getters";
import { dispatchUserLogOut } from "./store/main/actions";

@Component({
  components: {
    NotificationsManager,
    MainMenuDrawer,
  },
})
export default class App extends Vue {
  showDrawer = false;

  public mounted() {
    document.body.style.overflow = "hidden";
    window.addEventListener("resize", this.onResize);
  }
  public unmounted() {
    window.removeEventListener("resize", this.onResize);
  }
  onResize() {
    document.body.style.height = window.innerHeight + "px";
  }

  get menuItemsDrawer() {
    return [
      {
        title: "Home",
        icon: "mdi-map",
        link: "/",
      },
      this.userProfile
        ? {
            title: "Profile",
            icon: "mdi-account",
            link: "/profile",
          }
        : {
            title: "Create Account",
            icon: "mdi-account-plus",
            link: "/signup",
          },
    ];
  }
  get menuItemsAppBar() {
    if (this.userProfile) {
      return [
        {
          title: "Home",
          icon: "mdi-map",
          link: "/",
        },
        {
          title: "Profile",
          icon: "mdi-account",
          link: "/profile",
        },
        {
          title: "Logout",
          icon: "mdi-logout",
          click: this.logout,
        },
      ];
    } else {
      return [
        {
          title: "Login",
          icon: "mdi-login",
          link: "/login",
        },
        {
          title: "Signup",
          icon: "mdi-account-plus",
          link: "/signup",
        },
      ];
    }
  }
  get userProfile() {
    return readUserProfile(this.$store);
  }
  async logout() {
    await dispatchUserLogOut(this.$store);
    this.$router.push("/");
  }
}
